import { Component, Input } from '@angular/core';

import { Router } from '@angular/router';
import { Company } from 'src/app/model/company.interface';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {

  @Input() apiUrl: string = '';
  @Input() entity: string = '';
  @Input() columnHeader!: any;
  @Input() createButtonText: string ='';
  @Input() search = true;
  @Input() isHiddenQuery: boolean = false;
  @Input() filterButton = false;

  constructor(
    private router: Router
  ) { }

  goToCreate(): void {
    this.router.navigate([this.entity, 'create']);
  }

  rowClicked({id}: Company): void {
    this.router.navigate([this.entity, 'detail', id]);
  }
}
