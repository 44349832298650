import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { FilterConfig, SearchConfig } from 'src/app/model/search.interface';
import { FilterDialogComponent } from '../filter/filter-dialog.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy {

  filterValue: FilterConfig;
  @Output() search: EventEmitter<SearchConfig> = new EventEmitter<SearchConfig>();
  _ngUnsubscribe$: Subject<void> = new Subject<void>();
  @Input() filterButton: boolean = false;


  searchForm: FormGroup = this.fb.group({
    searchByName: '',
    filters: this.fb.group({
      status: null,
      country: null
    })
  });

  get searchByName(): FormControl {
    return this.searchForm.get('searchByName') as FormControl;
  }

  get filters(): FormGroup {
    return this.searchForm.get('filters') as FormGroup;
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.searchByName.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe$),
    ).subscribe(() => {
      this.search.emit(this.searchForm.value);
    });
  }

  openFilter(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      width: '250px',
      data: {
        form: this.filters,
        value: this.filterValue
      }});

    dialog.afterClosed().subscribe(res => {
      this.filterValue = res?.value;
      this.search.emit(this.searchForm.value);
    });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
