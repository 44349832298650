import { NgModule } from '@angular/core';
import { DataTableComponent } from './data-table/data-table.component';
import { MaterialModule } from '../modules/layout/material/material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrimValueAccessorDirective } from './trim.directive';
import { ListComponent } from './list/list.component';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { MultiselectAutocompleteComponent } from './multiselect-autocomplete/multiselect-autocomplete.component';
import { VideoComponent } from './video/video.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LibraryModalComponent } from './library-modal/library-modal.component';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MultiselectSkillCategoriesComponent } from './multiselect-skill-categories/multiselect-skill-categories.component';
import { FilterDialogComponent } from './filter/filter-dialog.component';
import { SearchComponent } from './search/search.component';
import { ColorPickerModule } from 'ngx-color-picker';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ColorPickerModule,
    NgxFileDropModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DataTableComponent,
    ColorPickerModule,
    TrimValueAccessorDirective,
    ListComponent,
    CopyToClipboardDirective,
    MultiselectAutocompleteComponent,
    AutocompleteComponent,
    VideoComponent,
    MediaUploadComponent,
    MultiselectSkillCategoriesComponent
  ],
  declarations: [
    ListComponent,
    DataTableComponent,
    TrimValueAccessorDirective,
    CopyToClipboardDirective,
    MultiselectAutocompleteComponent,
    VideoComponent,
    ConfirmDialogComponent,
    LibraryModalComponent,
    MediaUploadComponent,
    AutocompleteComponent,
    MultiselectSkillCategoriesComponent,
    FilterDialogComponent,
    SearchComponent
  ],
  providers: [],
})
export class SharedModule {}
