import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private _apiServerUrl = `https://${environment.mediaServerUrl}`;
  private _restPort = '8444';
  private _apiToken = 'aGlnaGVyOjIwMTdzcHhQWFAu';

  constructor(
    private http: HttpClient,
  ) { }

  startRecording(streamId: string, streamName: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Basic ${this._apiToken}`
    });

    return this.http
      .post(
        `${this._apiServerUrl}:${this._restPort}/rest-api/stream/startRecording`,
        { mediaSessionId: streamId } ,
        { headers, responseType: 'text' }
      ).pipe(
        map(() => {
          const fileName = `stream-${streamName}-${streamId}`;

          return `${this._apiServerUrl}/records/${fileName}`;
        })
      );
  }

  uploadVideo(file: File): Observable<HttpEvent<string>> {
    const headers = new HttpHeaders({
      apptoken: localStorage.getItem('token')
    });

    const formData = new FormData();
    formData.append('nativeCam', file);

    return this.http
      .post(
        `${this._apiServerUrl}/rest/defaultHigher/upload`,
        formData,
        {
          headers,
          responseType: 'text',
          reportProgress: true,
          observe: 'events'
        }
      );
  }
}
