<form [formGroup]="searchForm">
  <mat-form-field>
    <mat-label>Search by name</mat-label>
    <input type="text"
           matInput
           [formControl]="searchByName">
  </mat-form-field>

  <button *ngIf="filterButton" class="filter-button" mat-raised-button color="primary" (click)="openFilter()"><mat-icon>filter_list_alt</mat-icon>
    Filter
  </button>
</form>
