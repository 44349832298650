import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {
  @Input() appContent = '';

  constructor(
    private el: ElementRef,
    private toastr: ToastrService,
    ) { }

  @HostListener('click')
  onClick(): void {
    if (!navigator.clipboard) {
      // code to support older versions of browsers
      let element: HTMLInputElement = null;
      const isInput = this.el.nativeElement instanceof HTMLInputElement;
      if (isInput) {
        element = this.el.nativeElement;
      } else {
        element = document.createElement('input');
        element.style.position = 'fixed';
        element.style.opacity = '0';
        element.value = this.appContent;
        document.body.appendChild(element);
      }
      element.select();
      document.execCommand('copy');
      element.setSelectionRange(0, 0);
      if (!isInput) {
        document.body.removeChild(element);
      }
      this.toastr.info(
        'Page link copied successfully'
      );
    } else {
      navigator.clipboard.writeText(this.appContent)
        .then(() => {
          this.toastr.info(
            'Page link copied successfully'
          );
        })
        .catch((err) => {
          this.toastr.error(err);
        });
    }
  }
}
