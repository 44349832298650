import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private loaderService: LoaderService,
    private toastr: ToastrService,
  ) { }

  handleBackendError(response: HttpErrorResponse): Observable<never> {
    this.loaderService.hide();
    let errorMessage = '';
    const errors = response.error?.errors;

    if (!errors) {
      return throwError(() => response);
    }

    if (typeof errors === 'string') {
      errorMessage = errors;
    } else {
      for (const [key, value] of Object.entries(errors)) {
        if (value === 'This value is already used.') {
        errorMessage += `Value ${key} is already used. `;
        } else {
          errorMessage += value + '\n';
        }
      }
    }

    this.toastr.error(errorMessage);
    return throwError(() => response);
  }
}
