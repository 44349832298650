import { Endpoints } from 'src/app/model/endpoints.interface';

export function endpoints(url: string, apiVersion: string): Endpoints {
  return {
    api:                      `${url}/${apiVersion}`,
    auth:                     `${url}/auth`,
    refresh:                  `${url}/auth/refresh`,
    logout:                   `${url}/${apiVersion}/refresh_tokens`,
    customers:                `${url}/${apiVersion}/customers`,
    self:                     `${url}/${apiVersion}/_self`,
    companies:                `${url}/${apiVersion}/companies`,
    branches:                 `${url}/${apiVersion}/branches`,
    locations:                `${url}/${apiVersion}/locations`,
    platforms:                `${url}/${apiVersion}/job_platforms`,
    countries:                `${url}/${apiVersion}/countries`,
    amsMunicipalities:        `${url}/${apiVersion}/ams_municipalities`,
    kpi:                      `${url}/${apiVersion}/admin/kpi`,
    statuses:                 `${url}/${apiVersion}/application_hiring_statuses`,
    tilTidCustomers:          `${url}/${apiVersion}/til_tid_customers`,
    tilTidOrganizationUnits:  `${url}/${apiVersion}/til_tid_organization_units`,
    candidates:               `${url}/${apiVersion}/candidates`,
    applications:             `${url}/${apiVersion}/applications`,
    enterprises:              `${url}/${apiVersion}/enterprises`,
    enterpriseManager:        `${url}/${apiVersion}/enterprise_managers`,
    jobCategories:            `${url}/${apiVersion}/job_categories`,
    admins:                   `${url}/${apiVersion}/admins`,
    adminCompanies:           `${url}/${apiVersion}/admin/companies`,
    universalJobs:            `${url}/${apiVersion}/universal_jobs`,
    auth2fa_totp:             `${url}/auth2fa_totp`,
    skillCategories:          `${url}/${apiVersion}/predefined_business_question_categories`,
    skillSubcategories:       `${url}/${apiVersion}/predefined_business_question_subcategories`,
    storage:                  `${url}/${apiVersion}/storage`,
    globalSkillQuestions:     `${url}/${apiVersion}/predefined_business_questions`,
    jobnetMunicipality:       `${url}/${apiVersion}/jobnet_municipalities`
  };
}
