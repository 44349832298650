<div class="chip-list-wrapper">
  <mat-chip-listbox #chipList>
    <ng-container *ngFor="let data of selectData">
      <mat-chip-option class="cardinal-colors" (click)="toggleSelection(data)">
        {{ language ? data.name[language] : data.name }}
        <mat-icon class="mat-chip-remove">cancel</mat-icon>
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox>
</div>

<mat-form-field class="full-width">
  <mat-label>{{ placeholder }}</mat-label>
    <input matInput type="text"
    [matAutocomplete]="auto"
    (input)="onInputChange($event)">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let data of filteredData">
      <div (click)="optionClicked($event, data)">
        <mat-checkbox [checked]="data.selected"
          (change)="toggleSelection(data)"
          (click)="$event.stopPropagation()">
          {{ language ? data.name[language] : data.name }}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>

