import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith, tap } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() filterName = 'name';
  @Input() endpoint!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() control!: FormControl;
  @Input() options: any[];
  @Input() skipItemUuid = '';
  @Input() readonly = false;
  @Input() nestedProp: string;
  @Input() enableClearInput = false;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputCleared: EventEmitter<any> = new EventEmitter<any>();
  filteredOptions$!: Observable<any[]>;
  selectedItem: any;


  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.handleOptions();
  }

  ngOnChanges({options}: SimpleChanges): void {
    if (options) this.handleOptions();
  }


  handleOptions(): void {
    if (this.readonly) return;

    if (!this.options) {
      this.http.get<any[]>(this.endpoint)
      .pipe(
        tap((options: any) => {
          this.options = options;
          this.filteredOptions$ = this.control.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value || '')),
            );
        })
      )
      .subscribe();
    } else {
      this.filteredOptions$ = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    }
  }


  private _filter(value: any): any[] {
    const filterValue =
    typeof value === 'string' || value instanceof String ? value.toLowerCase()
      : this.nestedProp ? value[this.filterName][this.nestedProp].toLowerCase()
      : value[this.filterName].toLowerCase();
    return this.options.filter(option => this.nestedProp ? option[this.filterName][this.nestedProp].toLowerCase().includes(filterValue)
      : option[this.filterName].toLowerCase().includes(filterValue));
  }

  onSelect(item: any): void {
    this.selectedItem = item;
    this.itemSelected.next(item);
  }

  displayFn(item: any): string {
    return this.readonly ? item
      : this.nestedProp ? item?.name[this.nestedProp] || item?.category[this.nestedProp]
      : item?.name || item?.category;
  }

  clearValue(evt: MouseEvent): void {
    this.selectedItem = null;
    this.control.setValue(null);
    this.inputCleared.emit();
    evt.stopPropagation();
  }
}
