import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CategoryName, SelectionData, SkillCategoryData } from 'src/app/model/category.interface';

@Component({
  selector: 'app-multiselect-skill-categories',
  templateUrl: './multiselect-skill-categories.component.html',
  styleUrls: ['./multiselect-skill-categories.component.scss']
})
export class MultiselectSkillCategoriesComponent implements OnInit, OnChanges, OnDestroy {

  rawData: SkillCategoryData[] = [];
  filteredData: SkillCategoryData[] = [];
  selectData: SkillCategoryData[] = [];
  firstInit = true;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  @Output() outcome: EventEmitter<SelectionData<SkillCategoryData>> = new EventEmitter<SelectionData<SkillCategoryData>>();

  @Output() removedCategory: EventEmitter<SkillCategoryData> = new EventEmitter<SkillCategoryData>();

  @Input() placeholder: string = 'Select Data';
  @Input() language: keyof CategoryName;
  @Input() control: FormControl<number[]>;
  @Input() category = false;
  @Input() set data(data: SkillCategoryData[]) {
    if (data.length) {
      data?.forEach((item) => {
        const findIfExist = this.rawData.find((category) => category.id === item.id);
        if (!findIfExist) this.rawData.push({ ...item, selected: false });
      });
      this.filteredData = this.filterData();
    }
    if (this.control && this.firstInit) {
      this.populateControlInput();
      this.firstInit = false;
    };
  };
  @Input() selectedFromOutsideKey: keyof SkillCategoryData = 'id';
  @Input() filterKey: keyof SkillCategoryData = 'name';
  @Input() categoryRemovedId: EventEmitter<number>;

  constructor() {}
  ngOnInit(): void {
    this.categoryRemovedId?.pipe(
      takeUntil(this._ngUnsubscribe),
    )
    .subscribe((removedCategoryId: number) => {
      this.rawData = this.rawData
        .filter((subcategory)=>subcategory.predefinedBusinessQuestionCategory.id !== removedCategoryId);
      this.selectData = this.selectData
        .filter((selectedSubcategory)=>selectedSubcategory.predefinedBusinessQuestionCategory.id !== removedCategoryId);
      const selectedSubcategories = this.selectData.map((selectedSubcategory)=>selectedSubcategory.id);
      this.control.setValue(selectedSubcategories);
      this.filteredData = this.filterData();
    });
  }

  populateControlInput(): void {
    if (this.control.value?.length) {
      this.control.value.forEach((item: number) => {
        const itemInFiltered = this.rawData
        .find((rawDataItem) => rawDataItem[this.selectedFromOutsideKey] === item);
          const alreadySelected = this.selectData.find((selectedData) => selectedData.id === itemInFiltered.id);
        if (itemInFiltered && !alreadySelected) {
          itemInFiltered.selected = true;
          this.selectData.push(itemInFiltered);
        }
      });
    }
  }

  ngOnChanges({control}: SimpleChanges): void {
    if (control) this.populateControlInput();
  }

  onInputChange(event: Event): void {
    this.filteredData = this.filterData(event.target as HTMLInputElement);
  }

  filterData(event?: HTMLInputElement): any[] {
    if (event?.value) {
      const filterValue = event.value.toLowerCase();
      return this.rawData.filter((item: any) => {
        const value = this.language ? item[this.filterKey][this.language] : item[this.filterKey];
        return value.toLowerCase().indexOf(filterValue) >= 0;
      });
    } else {
      return this.rawData.slice();
    }
  }
  ​
  optionClicked(event: Event, data: SkillCategoryData): void {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection(data: SkillCategoryData): void {
    data.selected = !data.selected;

    if (data.selected) {
      this.selectData.push(data);
    } else {
      const i = this.selectData.findIndex(value => value.id === data.id);
      if (i > -1) {
        this.selectData.splice(i, 1);
      }
      if (this.category) {
        this.removedCategory.emit(data);
      }
    }
    if (this.control) {
      const selectedIds = this.selectData.map((selectedData) => selectedData.id);
      this.control.setValue(selectedIds);
    }
    this.outcome.emit({
      allSelectedData: this.selectData,
      selectedData: data
    });
  };

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }
}
