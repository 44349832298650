import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, tap } from 'rxjs';
import { Tab } from 'src/app/model/modal.interface';
import { LoaderService } from 'src/app/services/loader.service';
import { CompanyService } from 'src/app/services/company.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-library-modal',
  templateUrl: './library-modal.component.html',
  styleUrls: ['./library-modal.component.scss']
})
export class LibraryModalComponent implements OnInit {
  selectedFile!: string;
  isVideo = true;
  displayAll = false;
  videoElement!: HTMLVideoElement;
  videoLibraryData: any;
  imageLibraryData: any;
  pullData$!: Observable<any>;

  @HostListener('document:fullscreenchange')
  @HostListener('document:webkitfullscreenchange')
  @HostListener('document:mozfullscreenchange')
  @HostListener('document:MSFullscreenChange')
  exitFromVideo(): void {
    if (!document.fullscreenElement) {
      this.videoElement.controls = false;
      this.videoElement.pause();
    }
  }

  get title(): string {
    return this.data.title;
  }

  get tabs(): Tab[] {
    return this.data.tabs;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private dialogRef: MatDialogRef<LibraryModalComponent>,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.loaderService.show();
    this.pullData$ = forkJoin(
      [
        this.companyService.getCompanyVideoLibrary(),
        this.companyService.getCompanyImageLibrary()
      ]
    )
    .pipe(
      tap(([video, image]) => {
          this.videoLibraryData = video;
          this.imageLibraryData = image;
          this.loaderService.hide();
          this.cdr.detectChanges();
        })
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  selectFile(url: string): void {
    this.selectedFile = url || '';
  }

  useFile(): void {
    if (this.selectedFile) {
      this.data.confirm({fileURL: this.selectedFile, isVideo: !!this.isVideo});
      this.closeModal();
    } else {
      this.toastr.error('You have to select file you want to add');
    }
  }

  openInFullScreen(element: any): void {
    this.videoElement = element;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    element.controls = true;
  }

  showMoreData(): void {
    this.displayAll = true;
  }

  changedTab(event: any): void {
    this.isVideo = !event.index;
    this.displayAll = false;
  }

}
