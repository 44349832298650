<div class="spx-recorder" [ngStyle]="{'z-index': uploadInProgress ? 1003 : 'none'}">
  <div class="upload-overlay"></div>

  <div class="recorder-container" *ngIf="recorderMode === recorderModeEnum.record">
    <div class="recorder">
      <div *ngIf="countdownSettings.displayOverlay && countdownSettings.enable" class="countdown-overlay">
        <div class="countdown-container">
          <span class="countdown-time">{{ countdownSettings.currentTime }}</span>
        </div>
      </div>
      <div #recorder></div>
    </div>

    <div class="video-controls">
      <button *ngIf="!recordingInProgress"
              type="button"
              mat-raised-button color="primary"
              [disabled]="(readyToRecord$ | async) === false"
              (click)="startRecording()">Record</button>
      <button *ngIf="!recordingInProgress"
              type="button"
              mat-raised-button color="primary"
              [disabled]="(readyToRecord$ | async) === false"
              (click)="cancelRecording()">Cancel</button>
      <button *ngIf="recordingInProgress"
              [disabled]="countdownSettings.displayOverlay"
              type="button"
              mat-raised-button color="primary"
              (click)="stopRecording()">Stop</button>
    </div>
  </div>

  <div *ngIf="recorderMode === recorderModeEnum.void && !hideCommands" class="initial-screen">
    <button type="button"
            [disabled]="uploadInProgress"
            mat-raised-button color="primary"
            (click)="initializeRecorder()">
            Record
    </button>
    <button type="button"
            [disabled]="uploadInProgress"
            mat-raised-button color="primary"
            (click)="videoUploadInput.click()">
            Upload file
    </button>
    <button type="button"
            *ngIf="useVideoLibrary"
            [disabled]="uploadInProgress"
            mat-raised-button color="primary"
            (click)="openVideoLibraryModal()">
            From library
    </button>
    <input #videoUploadInput hidden type="file" (change)="uploadVideo($event)" [accept]="allowedVideoFormats.join(', ')" />
  </div>

  <div class="upload-progress-bar" *ngIf="uploadInProgress">
    <div class="custom-progress-bar-wrapper">
      <div class="custom-progress-bar" [ngStyle]="{width: uploadProgress + '%'}"></div>
      <div class="percent">{{ uploadProgress + '%' }}</div>
    </div>
    <div class="optimization-in-progress" *ngIf="uploadInProgress && inProcessing">
      Video optimization in process...
    </div>
    <button mat-raised-button color="primary" (click)="cancelUpload()">Cancel</button>
  </div>
</div>
