import { DuunitoriPlatform } from 'src/app/model/company.interface';

export const COUNTRY_SPECIFIC_FIELDS = [
  'organizationNumber', 'vatNumber', 'location', 'postalCode', 'address', 'candidatePortalLanguage', 'customerPortalLanguage'
];
export const ALLOWED_IMAGE_TYPES = ['png', 'jpg', 'jpeg'];
export const SMS_TITLE_LENGTH = 11;

export enum WriteMode {
  create = 'create',
  edit = 'edit'
}

export enum PostalCodeLength {
  swe = 5,
  nor = 4,
  usa = 5,
  dan = 4,
  fin = 5,
  ger = 5,
}

export enum Platforms {
  ams = 'Ams',
  blocket = 'Blocket',
  finn = 'Finn',
  indeed = 'Indeed',
  nav = 'Nav',
  jobindex = 'Jobindex',
  duunitori = 'Duunitori',
  facebookAndInstagram = 'FacebookAndInstagram',
  verkstadsjobb = 'Verkstadsjobb',
  adway = 'Adway',
  besoksliv = 'Besoksliv',
  jobnet = 'Jobnet',
  tiktok = 'Tiktok',
  linkedIn = 'LinkedIn',
  higherChargeable = 'HigherChannels'
}



export type Platform = 'amsCompany'
                       | 'finnCompany'
                       | 'indeedCompany'
                       | 'jobindexCompany'
                       | 'duunitoriCompany'
                       | 'adwayCompany'
                       | 'besokslivCompany'
                       | 'jobnetCompany';

export const PUBLISHING_PLATFORMS: Map<Platforms, Platform> = new Map([
  [
    Platforms.ams,
    'amsCompany'
  ],
  [
    Platforms.finn,
    'finnCompany'
  ],
  [
    Platforms.indeed,
    'indeedCompany'
  ],
  [
    Platforms.jobindex,
    'jobindexCompany'
  ],
  [
    Platforms.duunitori,
    'duunitoriCompany'
  ],
  [
    Platforms.adway,
    'adwayCompany'
  ],
  [
    Platforms.besoksliv,
    'besokslivCompany'
  ],
  [
    Platforms.jobnet,
    'jobnetCompany'
  ]
]);

export const DuunitoriDefaultCampaign: DuunitoriPlatform = {
  campaign: 'Enabled',
  credits: 0,
  paymentMethod: 'N/A'
};

export const MAX_CANDIDATE_DATA_LIFE_TIME = setMaxCandidateDataLifetime();

function isLeapYear(year: number): boolean {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

function setMaxCandidateDataLifetime(): number {
  const date = new Date();
  const currentYear = date.getFullYear();
  const nextYear = currentYear + 1;
  let dataLifetime = isLeapYear(currentYear) ? 366 : 365;
  dataLifetime += isLeapYear(nextYear) ? 366 : 365;

  return dataLifetime;
}

export const INDEED_DEFAULT_SOURCE_NAME = 'Next u systems for Higher';
