import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileResponse, FileUpload } from '../model/upload-files.model';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  constructor(private http: HttpClient) {}

  uploadFile({fileName, base64, context}: FileUpload): Observable<FileResponse> {
    return this.http.post<FileResponse>(environment.storage + '/upload_file', {fileName, base64, context})
      .pipe(
        map((uploadedFile: FileResponse) => {
          return {...uploadedFile, fileName};
        })
      );
  };
}
