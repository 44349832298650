<ng-container *ngIf="pullData$ | async">
  <div class="dialog-header" matDialogTitle>
      <div class="dialog-header-actions">
          <button mat-icon-button matDialogClose>
              <mat-icon>close</mat-icon>
          </button>
      </div>
      <h3>{{ title }}</h3>
  </div>
  <mat-tab-group (selectedTabChange)="changedTab($event)">
      <mat-tab [label]="tabs[0].tabTitle" *ngIf="!!tabs[0]?.tabTitle">
          <mat-dialog-content>
              <p *ngIf="!videoLibraryData || videoLibraryData.length === 0">No data</p>
              <div class="library-data-wrapper">
                  <ng-container *ngFor="let data of videoLibraryData; let i = index">
                      <div class="library-data" *ngIf="displayAll || i < 4">
                          <div class="image-and-video-preview">
                              <video  class="preview-image"
                                      controlsList="nodownload"
                                      disablePictureInPicture="true"
                                      preload="none"
                                      [poster]="data.videoPoster"
                                      (click)="openInFullScreen($event.target)">
                                  <source [src]="data?.video" />
                              </video>
                          </div>
                          <div class="library-data-actions">
                              <mat-radio-button color="primary"
                                                name="selectedData"
                                                (change)="selectFile(data?.video)"></mat-radio-button>
                          </div>
                      </div>
                  </ng-container>
              </div>
              <div class="library-data-footer">
                  <button *ngIf="!displayAll && videoLibraryData && videoLibraryData.length > 0"
                          mat-raised-button color="primary"
                          class="btn primary"
                          (click)="showMoreData()">
                      <span>Show more</span>
                  </button>
              </div>
          </mat-dialog-content>
      </mat-tab>
      <mat-tab [label]="tabs[1].tabTitle" *ngIf="!!tabs[1]?.tabTitle">
          <mat-dialog-content>
              <p *ngIf="!imageLibraryData || imageLibraryData.length === 0">No data</p>
              <div class="library-data-wrapper">
                  <ng-container *ngFor="let data of imageLibraryData; let i = index">
                      <div class="library-data" *ngIf="displayAll || i < 4">
                          <div class="image-and-video-preview">
                              <img [src]="data" class="preview-image"/>
                          </div>
                          <div class="library-data-actions">
                              <mat-radio-button color="primary"
                                                name="selectedData"
                                                (change)="selectFile(data)"></mat-radio-button>
                          </div>
                      </div>
                  </ng-container>
              </div>
              <div class="library-data-footer">
                  <button *ngIf="!displayAll && imageLibraryData && imageLibraryData.length > 0"
                          mat-raised-button color="primary"
                          class="btn primary"
                          (click)="showMoreData()">
                      <span>Show more</span>
                  </button>
              </div>
          </mat-dialog-content>
      </mat-tab>
  </mat-tab-group>
  <mat-dialog-actions>
      <button mat-raised-button color="primary" class="btn primary" matDialogClose>
          <span>Cancel</span>
      </button>
      <button mat-raised-button color="primary" class="btn primary" (click)="useFile()">
          <span>Apply</span>
      </button>
  </mat-dialog-actions>
</ng-container>
