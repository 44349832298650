import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private _loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingCount = 0;

  constructor() { }

  get loader(): Observable<boolean> {
    return this._loaderStatus$.asObservable();
  }

  show(): void {
    if (this.loadingCount < 1) {
      this._loaderStatus$.next(true);
    }

    this.loadingCount += 1;
  }

  hide(): void {
    if (this.loadingCount > 0) {
      this.loadingCount -= 1;
    }

    if (this.loadingCount < 1) {
      this._loaderStatus$.next(false);
    }
  }
}
