// for general use with all possible url formats (included querry strings etc.)
// these functions should be modified and tested

export function getVideoNameFromUrl(videoUrl: string): string {
  const urlSegments = videoUrl.split('/');
  return urlSegments[urlSegments.length - 1];
}

export function getVideoMimeTypeFromFileName(videoFileName: string): string {
  const fileNameSegments = videoFileName.split('.');
  return fileNameSegments[fileNameSegments.length - 1];
}

export function changeVideoExtension(videoUrl: string, extension: string): string {
  const videoName = getVideoNameFromUrl(videoUrl);
  const videoExtension = getVideoMimeTypeFromFileName(videoName);
  return videoUrl.replace(`.${videoExtension}`, `.${extension}`);
}
