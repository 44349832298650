import { Component,
         OnInit,
         ViewChild,
         Input, AfterViewInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { TableDataService } from './table-data.service';
import { DataTableService } from '../../services/data-table.service';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from 'src/app/model/company.interface';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { SearchConfig } from 'src/app/model/search.interface';

@Component({
  selector: 'app-data-table',
  providers: [DataTableService, TableDataService],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() apiUrl: string = '';
  @Input() columnHeader: any;
  @Input() pageSize: number = 0;
  @Input() pageSizeOptions: number[] = [];
  @Input() search = true;
  @Input() sortDirection: SortDirection = '';
  @Output() rowClicked: EventEmitter<Company> = new EventEmitter<Company>();
  @Input() isHiddenQuery: boolean = false;
  @Input() filterButton = false;
  length: number = 0;

  objectKeys = Object.keys;
  dataSource!: TableDataService;

  searchData: SearchConfig;
  _ngUnsubscribe$: Subject<void> = new Subject<void>();
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private dataTableService: DataTableService,
              private errorHandlingService: ErrorHandlingService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.dataSource = new TableDataService(this.dataTableService, this.errorHandlingService);
    this.dataSource.loadData(this.apiUrl, '', this.sortDirection, 1, this.pageSize, this.isHiddenQuery);
    this.dataSource.length$.subscribe(total => this.length = total);
  }
  ngOnChanges({apiUrl}: SimpleChanges): void {
    if (this.dataSource && apiUrl) this.loadData();
  }

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage(this.searchData))
      )
      .subscribe();
  }

  onRowClicked(row: Company): void {
    this.rowClicked.emit(row);
  }

  loadDataPage(searchData?: SearchConfig): void {
    this.dataSource.loadData(
      this.apiUrl,
      searchData,
      this.sortDirection,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.isHiddenQuery
    );
  }

  onSearch(event: SearchConfig): void {
    this.searchData = event;
    this.paginator.firstPage();
    this.loadDataPage(event);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
