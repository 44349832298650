// import * as flashphoner from 'src/assets/flashphoner/flashphoner.min.js';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { STAGING_STREAMING_KEY } from '../resources/upload-resources';
import { LoaderService } from './loader.service';
import { init, createSession, Session, Stream, playFirstVideo } from '@flashphoner/websdk';
import { SESSION_STATUS, STREAM_STATUS, CONNECTION_QUALITY, TRANSPORT_TYPE } from '@flashphoner/websdk/src/constants';

@Injectable({
  providedIn: 'root'
})
export class FlashphonerService {
  private _readyToRecord$: Subject<boolean> = new Subject<boolean>();
  // private readonly SESSION_STATUS = flashphoner.constants.SESSION_STATUS;
  // private readonly STREAM_STATUS = flashphoner.constants.STREAM_STATUS;
  // private readonly CONNECTION_QUALITY = flashphoner.constants.CONNECTION_QUALITY;
  private _videoStreamingComplete$: Subject<void> = new Subject<void>();
  private _videoStreamingFailed$: Subject<void> = new Subject<void>();
  private _streamName!: string;
  private _mediaServerUrl = `wss://${environment.mediaServerUrl}:8443`;
  private _sdpMinBitrate = '1000';
  private _stream: Stream;
  private _session: Session;
  private _constraints = {
    video: {
      width: {min: 480, ideal: 640, max: 1024},
      height: {min: 320, ideal: 480, max: 768},
      minBitrate: 1000,
      maxBitrate: 5000
    },
    audio: true,
  };

  constructor(
    private toastr: ToastrService,
    private loaderService: LoaderService,
  ) { }

  get readyToRecord$(): Observable<boolean> {
    return this._readyToRecord$.asObservable();
  }

  get stream(): Stream {
    return this._stream;
  }

  get videoStreamingComplete$(): Observable<void> {
    return this._videoStreamingComplete$.asObservable();
  }

  get videoStreamingFailed$(): Observable<void> {
    return this._videoStreamingFailed$.asObservable();
  }

  initialize(): void {
    try {
      init({
        flashMediaProviderSwfLocation: 'assets/flashphoner/media-provider.swf',
        logger: {severity: 'WARN'}
      });
    } catch (error) {
      console.error(error);
      this.toastr.error('Video streaming failed to initialize');
    }
  }

  playFirstVideo(recorder: HTMLDivElement): Promise<any> {
    return playFirstVideo(
      recorder,
      true,
      'https://customer.uhigher.com/assets/videos/preloader.mp4'
    );
  }

  createSession(recorder: HTMLDivElement): void {
    this._streamName = this.createUUID(8);

    // const appToken = environment.production
    //   ? localStorage.getItem('token')
    //   : STAGING_STREAMING_KEY;
      const appToken = STAGING_STREAMING_KEY;

    createSession({
        urlServer: `${this._mediaServerUrl}/${this._streamName}`,
        appKey: 'YWvER!eb&edEyWT@@TkWAg2c',
        custom: { appToken }
      })
      .on(SESSION_STATUS.ESTABLISHED, async (session: Session) => {
        this._session = session;
        this.startStream(recorder);
      })
      .on(SESSION_STATUS.DISCONNECTED, () => {
        this._session = null;
        this.loaderService.hide();
      })
      .on(SESSION_STATUS.FAILED, () => {
        this._session = null;
        this.toastr.error(
          'Streaming failed',
          'Error'
        );
        this.loaderService.hide();
      });
  }

  private startStream(recorder: HTMLDivElement): void {
    const createStreamOptions: any  = {
      name: this._streamName,
      display: recorder,
      disableConstraintsNormalization: true,
      receiveVideo: false,
      receiveAudio: false,
      transport: TRANSPORT_TYPE.UDP,
      constraints: this._constraints,
      sdpHook: ({sdpString}: {sdpString: string}) => {
        const sdpStringFind = 'a=fmtp:(.*) (.*)';
        const sdpStringReplace = `a=fmtp:$1 $2;x-google-max-bitrate=5000;x-google-min-bitrate=${this._sdpMinBitrate}`;
        let newSdp = sdpString;
        newSdp = newSdp.replace(new RegExp(sdpStringFind, 'g'), sdpStringReplace);

        return newSdp;
      }
    };
    this._stream = this._session
      .createStream(createStreamOptions)
      .on(STREAM_STATUS.PUBLISHING, () => {
        this.loaderService.hide();
        this._readyToRecord$.next(true);
        this.toastr.success('Streaming video!');
      })
      .on(STREAM_STATUS.UNPUBLISHED, () => {
        this.toastr.success('Stream unpublished!');
        this._stream = null;
        this._session?.disconnect();
        this._videoStreamingComplete$.next();
      })
      .on(STREAM_STATUS.FAILED, () => {
        this._stream = null;
        this._session?.disconnect();
        this._videoStreamingFailed$.next();

        this.toastr.error(
          'Streaming failed',
          'Error'
        );
      })
      .on(CONNECTION_QUALITY.UPDATE, (streamQuality: string) => {
      });

    this._stream.publish();
  }

  private createUUID(length: number): string {
    const uuidArray = [];
    const hexDigits = '0123456789abcdef';
    let start: number;

    for (let i = 0; i < 36; i++) {
      start = Math.floor(Math.random() * 0x10);
      uuidArray[i] = hexDigits.substring(start, start + 1);
    }

    uuidArray[14] = '4';
    // eslint-disable-next-line no-bitwise
    start = ((uuidArray as any[])[19] & 0x3) | 0x8;
    uuidArray[19] = hexDigits.substring(start, start + 1);
    uuidArray[8] = uuidArray[13] = uuidArray[18] = uuidArray[23] = '-';

    return uuidArray.join('').substring(0, length);
  }
}
