import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Country } from 'src/app/model/company.interface';
import { FilterConfig } from 'src/app/model/search.interface';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDialogComponent implements OnInit {

  data: { form: FormGroup, value: FilterConfig };
  @Input() filterForm: FormGroup;

  filterList = [
    {
      controlName: 'status',
      radioSelection: {
        label: 'Choose status',
        trueLabel: 'Active',
        falseLabel: 'Inactive'
      }
    },
    {
      controlName: 'country',
      dropdownSelection: {
        label: 'Choose country',
        options: this.getCountries()
      }
    }
  ];

  constructor(
    private companyService: CompanyService,
    private dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
  ) { }

  ngOnInit(): void {
    this.data = this.dialogData;
    this.filterForm = this.data.form;
    if (this.data.value) {
      this.filterForm.setValue(this.data.value);
    }
  }

  getCountries(): Observable<Country[]> {
    return this.companyService.getCountries();
  }

  toggleFilter(checked: boolean, abstractControl: AbstractControl): void {
    const control = abstractControl as FormControl;
    if (control.value === checked) {
      control.setValue(null);
    } else {
      control.setValue(checked);
    }
  }

  applyFilter(): void {
    const hasFilters = Object
    .values(this.filterForm.value)
    .filter(filter => {
      return filter !== null;
    });

    if (hasFilters) {
      this.dialogRef.close({value: this.filterForm.value });
    }
  }

  clearFilter(): void {
    this.filterForm.reset();
  }
}
