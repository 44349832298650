import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '../model/company.interface';
import { Pagination } from '../model/pagination.interface';
import { SearchConfig } from '../model/search.interface';

@Injectable()
export class DataTableService {
  constructor(private http: HttpClient) {}

  getData(
    apiUrl: string,
    search: SearchConfig | string,
    sortedByName: string = '',
    pageNumber: number = 1,
    pageSize: number = 10,
    isHiddenQuery: boolean = false
  ): Observable<Pagination<Company>> {
    let params = new HttpParams();

    if (sortedByName !== '') {
      params = params.append('sortByNameCriteria', sortedByName);
    }

    if (search) {
      const searchObj = (search as SearchConfig);
      if (searchObj.searchByName) {
        params = params.append('searchByName', searchObj.searchByName);
      }

      if (searchObj.filters?.status !== null) {
        params = params.append('active', searchObj.filters?.status);
      }

      if (searchObj.filters?.country) {
        params = params.append('country', searchObj.filters?.country);
      }
    }

    params = params.append('page', pageNumber.toString());
    params = params.append('limit', pageSize.toString());

    if (isHiddenQuery) {
      params = params.append('hidden', true.toString());
    } else if (!apiUrl.includes('predefined_business_questions')) {
      params = params.append('hidden', false.toString());
    }

    return this.http.get<Pagination<Company>>(apiUrl, { params }).pipe(
      map((res) => res)
    );
  }
}
