import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef,
         Component,
         ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, catchError, throwError ,EMPTY, Subject, take } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { VideoService } from 'src/app/services/video.service';
import { FlashphonerService } from 'src/app/services/flashphoner.service';
import { DEFAULT_COUNTDOWN_TIME, RecorderCountdownSettings, RecorderMode } from 'src/app/model/video.interface';
import { CheckDeviceService } from 'src/app/services/check-device.service';
import { Observable, takeUntil } from 'rxjs';
import { ALLOWED_VIDEO_FORMATS } from 'src/app/resources/allowed-file-formats';
import { SelectedMedia } from 'src/app/model/select.interface';
import { LibraryModalComponent } from '../library-modal/library-modal.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {
  @ViewChild('recorder') recorderContainer!: ElementRef<HTMLDivElement>;

  @Output() videoUploadComplete: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() videoRecordingCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() videoStreamingComplete: EventEmitter<string> = new EventEmitter<string>();
  @Output() videoUploadInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() hideCommands!: boolean;
  @Input() useVideoLibrary = true;
  readonly allowedVideoFormats = ALLOWED_VIDEO_FORMATS;
  private _uploadSubscription!: Subscription;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  uploadInProgress = false;
  uploadProgress = 0;
  inProcessing = false;
  uploadProgressInterval!: number;
  userMedia!: MediaStream;
  recorderModeEnum = RecorderMode;
  recorderMode: RecorderMode = RecorderMode.void;
  isRecordingCanceled = false;
  fileSizeLimit = 1048576000; // ~ 100MB
  videoMp4Url = '';
  videoWebmUrl = '';

  recordingInProgress = false;
  readyToRecord$: Observable<boolean> = this.flashphonerService.readyToRecord$;

  countdownSettings: RecorderCountdownSettings = {
    enable: true,
    time: DEFAULT_COUNTDOWN_TIME,
    currentTime: DEFAULT_COUNTDOWN_TIME,
    displayOverlay: false
  };

  get recorder(): HTMLDivElement {
    return this.recorderContainer?.nativeElement;
  }

  get stream(): any {
    return this.flashphonerService.stream;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private videoService: VideoService,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private flashphonerService: FlashphonerService,
    private checkDevice: CheckDeviceService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.flashphonerService.videoStreamingComplete$
    .pipe(
      takeUntil(this._ngUnsubscribe)
    )
    .subscribe(() => {
      if (this.isRecordingCanceled) {
        this.isRecordingCanceled = false;
      } else {
        this.recorderMode = RecorderMode.play;
        this.videoStreamingComplete.emit(this.videoMp4Url);
      }
      this.cdr.detectChanges();
      this.loaderService.hide();
    });

  this.flashphonerService.videoStreamingFailed$
    .pipe(
      takeUntil(this._ngUnsubscribe)
    )
    .subscribe(() => {
      this.recorderMode = RecorderMode.void;
      this.cdr.detectChanges();
      this.loaderService.hide();
    });
  }

  upload(file: File): void {
    this.uploadInProgress = true;
    this.videoUploadInProgress.emit(true);
    this.uploadProgress = 0;
    this.cdr.detectChanges();
    this._uploadSubscription = this.videoService.uploadVideo(file)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.uploadInProgress = false;
          this.videoUploadInProgress.emit(false);
          this.uploadProgress = 0;
          this.inProcessing = false;
          this.cdr.detectChanges();
          return EMPTY;
        }),
      )
      .subscribe((httpEvent: HttpEvent<string>) => this.handleUploadEvent(httpEvent));
  }

  handleUploadEvent(httpEvent: HttpEvent<string>, omitUploadProgress?: boolean): void {
    if (httpEvent.type === HttpEventType.Response) {
      this.uploadProgress = 100;
      this.cdr.detectChanges();
      const videoUrl = httpEvent.body;
      this.uploadInProgress = false;
      this.videoUploadInProgress.emit(false);
      this.inProcessing = false;
      this.cdr.detectChanges();
      this.videoUploadComplete.emit(videoUrl);
      return;
    }

    if (!omitUploadProgress && httpEvent.type === HttpEventType.UploadProgress && httpEvent.loaded && httpEvent.total) {
      if (this.uploadProgress < 70) {
        this.uploadProgress = Math.round((70 * httpEvent.loaded) / httpEvent.total);
      }

      if (this.uploadProgress === 70) {
        this.inProcessing = true;
        this.setUploadInterval();
      }

      this.cdr.detectChanges();
    }
  }

  setUploadInterval(intervalTime: number = 4500): void {
    if (this.uploadProgressInterval) {
      return;
    }

    this.uploadProgressInterval = window
      .setInterval(() => {
        if (this.uploadProgress === 100 || !this.uploadInProgress) {
          window.clearInterval(this.uploadProgressInterval);
          this.uploadProgressInterval = 0;
          return;
        }

        this.uploadProgress += 1;
        this.cdr.detectChanges();
      }, intervalTime);
  }

  cancelUpload(): void {
    this._uploadSubscription.unsubscribe();
    this.uploadInProgress = false;
    this.videoUploadInProgress.emit(false);
    this.inProcessing = false;
  }

  async initializeRecorder(): Promise<void> {
    this.loaderService.show();

    const permissionsDenied = await this.checkDevicePermissions();

    if (permissionsDenied) {
      this.loaderService.hide();
      return;
    }

    this.flashphonerService.initialize();

    if (this.uploadInProgress) {
      return;
    }

    this.recorderMode = RecorderMode.record;
    this.cdr.detectChanges();

    await this.startStreaming();
  }

  async checkDevicePermissions(): Promise<boolean> {
    if ('mediaDevices' in navigator) {
      try {
        this.userMedia = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
        return false;
      } catch (error) {
        this.showPermissionsModal();
        return true;
      }
    } else {
      this.toastr.error('Your device doesn\'t support video streaming.');
      return true;
    }
  }

  showPermissionsModal(): void {
    const data = {
      title: 'Device permission',
      content: 'In order to record video and complete the application process, you need to allow access to camera and microphone. Please allow access to camera and microphone for this application by updating permissions in browser settings'
    };

    const dialogConfig: MatDialogConfig = {
      data,
      panelClass: 'permission-modal',
    };
    const dialog = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialog.afterClosed()
      .pipe(
        take(1)
      )
      .subscribe(() => {
        this.videoRecordingCanceled.emit();
      });
  }

  async startStreaming(): Promise<void> {
    if (this.checkDevice.isSafari()) {
      await this.flashphonerService.playFirstVideo(this.recorder);
      this.renderer.setAttribute(
        this.recorder.querySelector('video'),
        'playsinline',
        ''
      );
      this.cdr.detectChanges();
    }

    this.flashphonerService.createSession(this.recorder);
  }

  openVideoLibraryModal(): void {
    const data = {
      title: 'Use previously added image or video',
      tabs: [
        {active: true, tabTitle: 'Video', isVideo: true},
      ],
      confirm: ({fileURL, isVideo}: SelectedMedia) => this.videoUploadComplete.emit(fileURL),
    };

    const dialogConfig: MatDialogConfig = {
      data,
      panelClass: 'library-modal',
      width: '90vw',
      maxWidth: '950px'
    };
    this.dialog.open(LibraryModalComponent, dialogConfig);
  }

  cancelRecording(): void {
    this.isRecordingCanceled = true;
    this.recorderMode = RecorderMode.void;
    this.stopRecording();
    this.videoRecordingCanceled?.emit();
    this.stopCameraAndMic();
  }

  stopCameraAndMic(): void {
    this.userMedia?.getTracks()
      .forEach(track => track.stop());
  }

  stopRecording(): void {
    this.loaderService.show();
    this.stream?.stop();
    this.recordingInProgress = false;
    this.cdr.detectChanges();
  }

  startRecording(): void {
    this.recordingInProgress = true;
    this.recorderMode = RecorderMode.record;

    if (!this.countdownSettings.enable) {
      this.sendStartRecordingRequest();
      return;
    }

    this.countdownSettings.displayOverlay = true;
    this.cdr.detectChanges();

    const timerInterval = setInterval(() => {
      if (this.countdownSettings.currentTime > 0) {
        this.countdownSettings.currentTime -= 1;
        this.cdr.detectChanges();
      } else {
        clearInterval(timerInterval);
        this.countdownSettings.displayOverlay = false;
        this.countdownSettings.currentTime = this.countdownSettings.time;
        this.cdr.detectChanges();

        this.sendStartRecordingRequest();
      }
    }, 1000);
  }

  sendStartRecordingRequest(): void {
    const streamId = this.stream.id();
    const streamName = this.stream.name();

    this.videoService.startRecording(streamId, streamName)
      .subscribe((videoUrl: string) => {
        this.videoMp4Url = `${videoUrl}.mp4`;
        this.videoWebmUrl = `${videoUrl}.webm`;

        this.cdr.detectChanges();
      });
  }

  uploadVideo(event: Event): void {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    if (this.uploadInProgress || files?.length === 0) {
      this.loaderService.hide();
      return;
    }

    if ((files?.length ?? 0)> 1) {
      this.toastr.error('You can only upload one video');
      this.loaderService.hide();
      return;
    }

    const file = files?.[0];

    if ((file?.size ?? 0) > this.fileSizeLimit) {
      this.loaderService.hide();
      this.toastr.error('Files must be smaller than 1000MB');
      return;
    }

    if (!ALLOWED_VIDEO_FORMATS.includes(file?.type ?? '')) {
      this.toastr.error('Uploading this type of file is not allowed');
      this.loaderService.hide();
      this.cdr.detectChanges();
      return;
    }

    this.uploadInProgress = true;
    this.uploadProgress = 0;
    this.cdr.detectChanges();

    if (file) {
      this.upload(file);
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.stream?.stop();

    await this.stopCameraAndMic();

    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
