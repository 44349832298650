import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { SelectedMedia } from 'src/app/model/select.interface';
import { ALLOWED_IMAGE_FORMATS, ALLOWED_VIDEO_FORMATS } from 'src/app/resources/allowed-file-formats';
import { changeVideoExtension } from 'src/app/resources/video-url-transformation-functions';
import { LoaderService } from 'src/app/services/loader.service';
import { UploadFilesService } from 'src/app/services/upload-file.service';
import { UploadImageService } from 'src/app/services/upload-image.service';
import { LibraryModalComponent } from '../library-modal/library-modal.component';
import { VideoComponent } from '../video/video.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, catchError, map, throwError } from 'rxjs';
import { FileResponse, FileUpload } from 'src/app/model/upload-files.model';

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss']
})
export class MediaUploadComponent {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLMediaElement>;
  @ViewChild('videoComponent') videoComponent!: VideoComponent;
  @Output() setIMutableQuestion: EventEmitter<void> = new EventEmitter<void>();
  @Input() form!: FormGroup;
  @Input() video!: FormControl;
  @Input()
  set videoUrl(value: string) {
    if (!!value) {
      this.setVideoExtensions(value);
    }
  }
  @Input() picture!: FormControl;
  @Input() imageUploadContext!: string;
  readonly allowedVideoAndImageFormats = [...ALLOWED_VIDEO_FORMATS, ...ALLOWED_IMAGE_FORMATS];
  isRecording = false;
  uploadInProgress!: boolean;
  uploadInProgressInVideoComponent = false;
  videoRecorded: boolean;
  replacingFile: boolean;

  videoPosterUrl: string;
  videoWebmUrl: string;
  videoMp4Url: string;

  get isDragAndDropDisabled(): boolean {
    return !!this.picture?.value || !!this.video?.value || this.isRecording;
  }

  get isMediaUploaded(): boolean {
    return !!this.picture?.value || this.video?.value;
  }

  get isImageUploaded(): boolean {
    return !!this.picture?.value;
  }

  constructor(
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private uploadImageService: UploadImageService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private uploadFilesService: UploadFilesService,
  ) { }

  setVideoExtensions(videoUrl: string): void {
    this.videoPosterUrl = videoUrl ? changeVideoExtension(videoUrl, 'jpg') : null;
    this.videoMp4Url = videoUrl ? changeVideoExtension(videoUrl, 'mp4') : null;
    this.videoWebmUrl = videoUrl ? changeVideoExtension(videoUrl, 'webm') : null;
  }

  async mediaUpload(data: NgxFileDropEntry[]): Promise<void> {
    this.setIMutableQuestion.emit();
    if (data.length > 1) {
      this.toastr.error('Uploading more than 1 file is not allowed');
      return Promise.reject();
    }
    this.loaderService.show();
    try {
      const {file, isVideo} = await this.uploadImageService.checkMediaForUpload(data)[0];
      if (isVideo) {
        this.startVideoUpload(file);
        this.loaderService.hide();
      } else {
        const image = await this.uploadImageService.readUploadedFileAsURL(file);
        this.uploadImageToCloud(file.name, image);
      }
    } catch (error) {
      this.loaderService.hide();
    }
  }

  uploadImageToCloud(fileName: string, image: string): void {
    let request$: Observable<string>;

    if (!this.imageUploadContext) return;

    if (this.imageUploadContext) {
      const fileUpload: FileUpload = {
        context: this.imageUploadContext,
        fileName,
        base64: image.split(',')[1]
      };

      request$ = this.uploadFilesService.uploadFile(fileUpload)
        .pipe(
          map((file: FileResponse) => file.link)
        );
    }

    request$
      .pipe(
        catchError((error) => {
          this.loaderService.hide();
          this.toastr.error('You need to upload an image');
          return throwError(() => error);
        })
      )
      .subscribe((imageURL: string) => {
        this.picture?.enable();
        this.picture?.setValue(imageURL);
        this.video.disable();
        this.video.reset();
        this.loaderService.hide();
        this.toastr.success('File uploaded');
        this.form.markAsDirty();
        this.cdr.detectChanges();
      });

  }

  imageReset(): void {
    this.loaderService.show();
    this.picture?.setValue(null);
    this.form.markAsDirty();
    this.video.enable();
    this.loaderService.hide();
  }

  videoReset(): void {
    this.video.setValue(null);
    this.form.updateValueAndValidity();
    this.form.markAsDirty();
    this.picture?.enable();
    this.setVideoExtensions(null);
  }

  async replaceFile(event: Event): Promise<void> {
    const files = (event.target as HTMLInputElement).files;
    if (!files?.length) {
      return;
    }

    const file = files[0];
    const isVideo = this.uploadImageService.checkIsVideoFile(file);

    if (!isVideo) {
      this.loaderService.show();
      try {
        const image = await this.uploadImageService.readUploadedFileAsURL(file);
        this.uploadImageToCloud(file.name, image);
      } catch (error) {
        this.loaderService.hide();
      }
    } else {
      this.replacingFile = true;
      setTimeout(() => {
        this.startVideoUpload(file);
      }, 600);
    }
  }

  useImageAndVideoLibrary(): void {
    this.setIMutableQuestion.emit();
    const data = {
      title: 'Use previously added image or video',
      tabs: [
        {active: true, tabTitle: 'Video', isVideo: true},
        {active: false, tabTitle: 'Image', isVideo: false},
      ],
      confirm: ({fileURL, isVideo}: SelectedMedia) => {
        if (isVideo) {
          this.videoReady(fileURL);
        } else {
          this.picture?.enable();
          this.picture?.setValue(fileURL);
          this.video.patchValue('');
        }
        this.cdr.detectChanges();
      },
    };

    const dialogConfig: MatDialogConfig = {
      data,
      panelClass: 'library-modal',
      width: '90vw',
      maxWidth: '950px'
    };
    this.dialog.open(LibraryModalComponent, dialogConfig);
  }

  videoReady(videoUrl: string): void {
    this.isRecording = false;
    this.replacingFile = false;
    this.picture?.patchValue('');
    this.video?.enable();
    this.video?.setValue(videoUrl);
    this.setVideoExtensions(videoUrl);
    this.cdr.detectChanges();
    this.videoPlayer?.nativeElement.load();
    this.form?.updateValueAndValidity();
  }

  startVideoUpload(file: File): void {
    this.videoComponent.upload(file);
  }

  openMergeVideosModal(): void {
  throw new Error('Method not implemented.');
  }

  cancelRecording(): void {
    this.isRecording = false;
  }

  initializeRecorder(): void {
    this.setIMutableQuestion.emit();
    this.isRecording = true;
    this.cdr.detectChanges();
    this.videoComponent.initializeRecorder();
  }

}
