<mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input type="text"
           [placeholder]="placeholder"
           matInput
           [formControl]="control"
           [matAutocomplete]="auto"
           [readonly]="readonly">
    <mat-icon *ngIf="enableClearInput"
              [style.visibility]="selectedItem ? 'visible': 'hidden'"
              matSuffix
              class="clear-input-btn"
              (click)="clearValue($event)">clear</mat-icon>
    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="onSelect($event.option.value)"
                      [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
        {{nestedProp ? option[filterName][nestedProp] : option[filterName]}}
        <span *ngIf="option.createdAt">{{ option.createdAt }}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required')">
      This field is required
    </mat-error>
</mat-form-field>
